/* eslint-disable @typescript-eslint/no-explicit-any */ // Disabling as d3 d parameter is not typed
import React, { useEffect, useRef } from 'react';
import { BASE_COLORS, SEMANTIC_COLORS } from 'themes/foundations/Colors';
import {
  select,
  scaleLinear,
  line,
  area,
  max,
  min,
  axisLeft,
  axisBottom,
  easeLinear,
  easeElastic,
  curveCatmullRom,
  bisector,
  pointer,
  type Selection
} from 'd3';

type plotData = Record<string, Array<string | number>>;

interface Props {
  plotData: plotData;
  plotType: string;
  plotTitle?: string;
  size?: 'default' | 'large';
  yLabel?: string;
  xLabel?: string;
}

const ChartsLine: React.FC<Props> = (props) => {
  const plotType = props.plotType;
  const plotData = props.plotData[plotType];
  const svgRef = useRef<SVGSVGElement>(null);
  const svgTooltipRef = useRef<HTMLDivElement>(null);
  const padding = props.size === 'large' ? 40 : 30;
  const paddingRange = padding * 2;
  const labelSize = props.size === 'large' ? 12 : 8;
  const xAxisTicks = 5;
  const yAxisTicks = 3;
  let handleResize: () => void;

  const drawChart = (): void => {
    if (plotData === undefined) return;

    const canvas = svgRef.current;
    if (canvas === null) {
      console.error('Canvas not found');
      return;
    }

    const cW = canvas.clientWidth - padding;
    const cH = canvas.clientHeight - paddingRange;

    // Create svg
    const svg = select(canvas);

    if (props.plotTitle !== undefined) {
      // Main chart title
      svg
        .append('text')
        .attr('class', 'chart-title')
        .text(props.plotTitle)
        .attr('x', '50%')
        .attr('y', 24)
        .attr('text-anchor', 'middle');
    }

    if (props.xLabel !== undefined) {
      svg
        .append('text')
        .attr('class', 'chart-axis-label')
        .text(props.xLabel)
        .attr('x', '100%')
        .attr('y', cH + paddingRange)
        .attr('text-anchor', 'end')
        .attr('font-size', labelSize)
        .attr('font-weight', 'bold');
    }

    if (props.yLabel !== undefined) {
      svg
        .append('text')
        .attr('class', 'chart-axis-label')
        .text(props.yLabel)
        .attr('x', 0)
        .attr('y', labelSize)
        .attr('font-size', labelSize)
        .attr('font-weight', 'bold');
    }

    // Create a group to contain the chart and center it
    const chart = svg
      .append('g')
      .attr('transform', `translate(${padding}, ${padding / 2})`);

    // Create scales x,y
    const xScale = scaleLinear()
      .domain([
        min(plotData, (d: any) => d[0]),
        max(plotData, (d: any) => d[0])
      ])
      .range([0, cW])
      .clamp(true); // To prevent values outside the domain from being returned by invert()

    const yScale = scaleLinear()
      .domain([0, max(plotData, (d: any) => d[1])])
      .range([cH, 0]);

    // Add axis
    chart
      .append('g')
      .attr('class', 'axis-left')
      .call(
        axisLeft(yScale)
          .ticks(yAxisTicks)
          .tickFormat((d) => `${d as number}%`)
      );

    const axisBottomGroup = chart
      .append('g')
      .attr('class', 'axis-bottom')
      .style('transform', `translateY(${cH}px)`)
      .call(axisBottom(xScale).ticks(xAxisTicks));

    // Create line
    const chartLine: any = line()
      .x((d) => xScale(d[0]))
      .y((d) => yScale(d[1]))
      .curve(curveCatmullRom.alpha(0.5));

    const linePath = chart
      .append('path')
      .attr('class', 'path-line')
      .attr('fill', 'none')
      .attr('stroke-width', 2)
      .attr('stroke', SEMANTIC_COLORS.INFO)
      .datum(plotData)
      .attr('d', chartLine);

    // Transform line (from 0 to full)
    const totalLength: number = linePath?.node()?.getTotalLength() ?? 0;
    linePath
      .attr('stroke-dasharray', `${totalLength} ${totalLength}`) // Set the dash length
      .attr('stroke-dashoffset', totalLength) // Offset the stroke to hide the line
      .transition()
      .duration(1000)
      .ease(easeLinear)
      .attr('stroke-dashoffset', 0); // Animate the offset revealing the line

    // Create gradient area
    const chartArea: any = area()
      .y0(cH)
      .y1((d) => yScale(d[1]))
      .x((d) => xScale(d[0]))
      .curve(curveCatmullRom.alpha(0.5));

    const areaGradient = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradient_area')
      .attr('gradientTransform', 'rotate(120)');

    areaGradient
      .append('stop')
      .attr('offset', '5%')
      .attr('stop-color', 'rgba(13, 188, 242, 0.05)');
    areaGradient
      .append('stop')
      .attr('offset', '60%')
      .attr('stop-color', 'transparent');

    const chartArea1 = chartArea.y0((d: any) => yScale(d[1]));
    const areaPath = chart
      .append('path')
      .attr('class', 'path-area')
      .attr('fill', 'url(#gradient_area)')
      .datum(plotData)
      .attr('d', chartArea1);

    // Transforming gradient area into view
    const chartArea2 = chartArea.y0(cH);
    areaPath
      .transition()
      .duration(2000)
      .delay(1000)
      .ease(easeElastic.period(0.7))
      .attr('d', chartArea2);

    // Hover effects
    let dataTooltip: Selection<HTMLSpanElement, unknown, null, undefined>;
    const bisect = bisector((d: any) => d[0]).left;

    const focusGroup = svg.append('g').style('display', 'none');

    const focusLine = focusGroup
      .append('line')
      .attr('class', 'focus-line')
      .attr('stroke', BASE_COLORS.BASE_40)
      .attr('stroke-dasharray', '4 4')
      .attr('stroke-width', 1)
      .attr('y1', 0)
      .attr('y2', cH);

    const focusDot = focusGroup
      .append('circle')
      .attr('class', 'focus-dot')
      .attr('r', 5)
      .attr('fill', SEMANTIC_COLORS.INFO);

    svg
      .append('rect')
      .attr('class', 'bisector')
      .attr('width', cW)
      .attr('height', cH)
      .attr('transform', `translate(${padding}, ${padding / 2})`)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', () => {
        focusGroup.style('display', null);
        dataTooltip = select(svgTooltipRef.current)
          .append('span')
          .attr('class', 'chart-tooltip__text');
      })
      .on('mouseout', () => {
        focusGroup.style('display', 'none');
        dataTooltip.remove();
      })
      .on('mousemove', function (event) {
        const [mouseX] = pointer(event, this);
        const xValue = xScale.invert(mouseX);

        const bisedIndex = bisect(plotData, xValue);
        const d: any = plotData[bisedIndex];

        const cx = xScale(d[0]) + padding;
        const cy = yScale(d[1]) + padding / 2;
        focusLine.attr('x1', cx).attr('x2', cx);
        focusDot.attr('cx', cx).attr('cy', cy);

        const key: string = d[0].toFixed(2);
        const value: string = d[1].toFixed(0);
        dataTooltip.text(`${key}: ${value}%`);
      });

    // Making chart responsive when the window resizes
    handleResize = (): void => {
      // Getting new canvas width
      const cWNew = canvas.clientWidth - padding;
      const cHNew = canvas.clientHeight - paddingRange;

      // Updating scales
      xScale.range([0, cWNew]);

      // Updating lines
      const chartLineNew: any = line()
        .x((d) => xScale(d[0]))
        .y((d) => yScale(d[1]))
        .curve(curveCatmullRom.alpha(0.5));

      linePath.datum(plotData).attr('d', chartLineNew);

      // Updating gradient area
      areaPath
        .datum(plotData)
        .transition()
        .duration(2000)
        .ease(easeElastic.period(0.7))
        .attr(
          'd',
          chartArea.y0(cHNew).x((d: any) => xScale(d[0]))
        );

      // Updating axis
      axisBottomGroup
        .style('transform', `translateY(${cHNew}px)`)
        .call(axisBottom(xScale).ticks(xAxisTicks));
    };

    window.addEventListener('resize', () => {
      handleResize();
    });
  };

  useEffect(() => {
    if (svgRef.current !== null) {
      // Clearing the canvas to avoid overlapping
      select(svgRef.current).selectAll('*').remove();
    }
    drawChart();
    return () => {
      // Clearing event listener to avoid dupplication
      window.removeEventListener('resize', handleResize);
    };
  }, [props.plotData]);

  return (
    <>
      <svg
        ref={svgRef}
        className="chart-line-canvas"
        width="100%"
        height="100%"
      />
      <div ref={svgTooltipRef} className="chart-tooltip"></div>
    </>
  );
};

export default ChartsLine;
