export const BASE_COLORS = {
  BASE_100: '#000000',
  BASE_90: '#101828',
  BASE_80: '#344054',
  BASE_70: '#667085',
  BASE_60: '#98a2b3',
  BASE_50: '#b4bcc8',
  BASE_40: '#d0d5dd',
  BASE_30: '#eaecf0',
  BASE_20: '#f2f4f7',
  BASE_10: '#f9fafb',
  BASE_0: '#ffffff'
};

export const ACCENTS = {
  ACCENT_0: '#A82AAB',
  ACCENT_32: '#812083',
  ACCENT_52: '#CB3ACF',
  ACCENT_62: '#D663D9',
  ACCENT_72: '#E18CE3',
  ACCENT_92: '#F6DEF7'
};

export const SEMANTIC_COLORS = {
  INFO_95: '#e7f8fe',
  INFO: '#0dbcf2',
  INFO_30: '#087191',
  SUCCESS_95: '#e7fef5',
  SUCCESS: '#0df29f',
  SUCCESS_30: '#089160',
  WARNING_95: '#fef3e7',
  WARNING: '#f2890d',
  WARNING_30: '#915208',
  ALERT_95: '#fee7e9',
  ALERT: '#f20d28',
  ALERT_30: '#910818'
};

export const CATEGORIES = {
  CAT_1: '#02044c',
  CAT_2: '#0f46b1',
  CAT_3: '#b1ebfd',
  CAT_4: '#003e3a',
  CAT_5: '#007682',
  CAT_6: '#d8fa77',
  CAT_7: '#7c0016',
  CAT_8: '#db4d26',
  CAT_9: '#ffd597',
  CAT_10: '#36025f',
  CAT_11: '#be0e78',
  CAT_12: '#ffafd6'
};

export function hexWithTransparencyPercentage(
  color: string,
  percentage = 100
): string {
  const decimal = `0${Math.round(255 * (percentage / 100)).toString(16)}`
    .slice(-2)
    .toUpperCase();
  return color + decimal;
}
